<template>
	<router-view/>
</template>
<script>
export default {
	sockets: {
		connect() {
			console.log('connect in socket...')
			this.$socket.emit('sockItToMe')
		},
		disconnect() {
			console.log('socket disconnected!')
		},
		sockItToYou(socketID){
									console.log('sock it to you',socketID)
			if(socketID){
				console.log('socketID')
				if(localStorage.socketPrevious == null){
					console.log('socketprev null',socketID)
					localStorage.setItem("socketPrevious",socketID)
					this.$store.state.userConnect.socketPrevious = socketID || null
				} else if(localStorage.socketPrevious !== socketID) {
					console.log('sock prev not equal to socket id');
					console.log(localStorage)
					console.log(localStorage.socketPrevious)
					console.log(localStorage.email)
					console.log('--')


					this.$socket.emit('updateSocket',{
						socketPrevious: localStorage.socketPrevious,
						email: localStorage.email
					})
	
					localStorage.setItem("socketPrevious",socketID)
					this.$store.state.userConnect.socketPrevious = localStorage.socketCurrent ? localStorage.socketCurrent : null
				}
	
				localStorage.setItem("socketCurrent",socketID)
				this.$store.state.userConnect.socketCurrent = socketID ? socketID : null
			}
		}
	},
	async created() {
		console.log('set storage')
		await this.$store.dispatch('setStorage',localStorage)
		//.then(()=> {this.loading = false})
	}
}
</script>
<style>
@import './assets/css/fontawesome.min.css';
@import './assets/css/duotone.min.css';

/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/
html{color:#000;background:#FFF}body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0}table{border-collapse:collapse;border-spacing:0}fieldset,img{border:0}address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}ol,ul{list-style:none}caption,th{text-align:left}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}q:before,q:after{content:''}abbr,acronym{border:0;font-variant:normal}sup{vertical-align:text-top}sub{vertical-align:text-bottom}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit;*font-size:100%}legend{color:#000}#yui3-css-stamp.cssreset{display:none}

html{box-sizing:border-box}*,:after,:before{box-sizing:inherit}
html,body {font-family:Arial,Helvetica,sans-serif;font-size: 62.5%;}

@font-face {
	font-family: 'Merriweather Regular';
	src: url(./assets/webfonts/Merriweather/Merriweather-Regular.ttf);
}

@font-face {
	font-family: 'Merriweather Black';
	src: url(./assets/webfonts/Merriweather/Merriweather-Black.ttf);
}

html,body {
	font-family: 'Merriweather Regular', sans-serif;
}

header {
	font-family: 'Merriweather Black', sans-serif;
}



:root {
	--tux-black-darker: rgba(20,17,18,.84);
	--tux-black: rgba(35,31,32,1);
	--tux-black-90: rgba(35,31,32,.90);
	--tux-black-60: rgba(35,31,32,.60);
	--tux-gray: rgba(169,169,169,1);
	--tux-gray-60: rgba(169,169,169,.60);
	--tux-gray-15: rgba(169,169,169,.15);
	--tux-red: rgba(220,20,60,1);
	--tux-red-60: rgba(220,20,60,.60);
	--tux-red-45: rgba(220,20,60,.45);
	--tux-white: rgba(255,255,255,1);
	--tux-white-90: rgba(255,255,255,.90);
	--tux-white-75: rgba(255,255,255,.75);
	--tux-white-60: rgba(255,255,255,.60);
	--tux-white-45: rgba(255,255,255,.45);
	--tux-white-15: rgba(255,255,255,.15);
	--tux-white-09: rgba(255,255,255,.09);
	--tux-white-03: rgba(255,255,255,.03);
	--bright-cyan: rgba(0,180,255,1);
	--crown-yellow: rgba(255,215,0,1);
	--dark-yellow: rgba(218,165,32,1);
	--intermediate-yellow: rgba(240,190,8,.90);
	--headerSize: 72px;
}



header,
#logo,
.panel-bar,
.inline-label-field,
a.button {
	align-items: center;
}

html,
body {
	background-color: var(--tux-black);
}

header {
	background-color: var(--tux-black-darker);
}

#guest-search.panel {
	background-color: var(--tux-black-60);
}

a.button {
	background-color: var(--tux-white);
}

dialog,
[popover],
.alert {
	background-color: var(--tux-white-90);
}

caption {
	background-color: var(--tux-white-75);
}

dialog button {
	background-color: var(--tux-black-90);
}

table th,
#search {
	background-color: var(--tux-white-60);
}

input,
textarea,
select,
button {
	background-color: var(--tux-white-09);
}

.panel,
tr:nth-child(even) {
	background-color: var(--tux-white-03);
}

#check-in-progress {
	background-color: var(--tux-red-45);
}

.active-button.button {
	background-color: var(--bright-cyan);
}

[popover] {
	background-color: var(--intermediate-yellow);
}

td button {
	background-color: transparent;
}

/* body {
	background-image: url(./assets/svg/shape-3.svg);
} */

header {
	background-image: url(./assets/svg/line-wave.svg);
}

main h1 {
	background-image: url(./assets/svg/art-deco-hdr.svg);
}

body,
header,
main h1 {
	background-repeat: no-repeat;
}

header {
	background-position: center;
}

body,
main h1 {
	background-position: center;
}

body {
	background-size: 54%;
}

header {
	background-size: cover;
}

header,
form,
.panel,
dialog::backdrop,
[popover],
#check-in-progress {
	backdrop-filter: blur(3px);
}

input,
textarea,
select {
	border: 0 none;
}

fieldset,
dialog,
button[disabled],
#check-in-progress {
	border: 1px solid var(--tux-gray-15);
}

a.button,
table th,
[popover] {
	border: 1px solid var(--tux-black-60);
}

table,
table td,
caption,
#guest-search {
	border: 1px solid var(--tux-white-45);
}

button {
	border: 1px solid var(--tux-white);
}

header {
	border-bottom: 1px solid var(--tux-gray-15);
}

input,
textarea,
select {
	border-bottom: 1px dashed var(--tux-white-15);
}

input:focus,
textarea:focus,
select:focus {
	border-bottom: 1px dashed var(--tux-white);
}

.alert {
	border: 1px solid var(--tux-red-60);
}

td button {
	border: 0 none;
}

fieldset,
dialog,
button,
table,
.panel,
a.button,
[popover] {
	border-radius: 3px;
}

dialog,
[popover] {
	box-shadow: 1px 1px 1px 1px var(--tux-white-15);
}

a.button,
caption,
table th,
.close-note,
#dialog-upload legend {
	color: var(--tux-black);
}

#dialog-upload input[type="file"],
#search {
	color: var(--tux-black-90);
}

header span,
main h1 {
	color: var(--tux-gray);
}

#logo i,
.check-in i {
	color: var(--tux-red);
}

.alert {
	color: var(--tux-red-60);
}

#logo,
main,
nav a,
input,
textarea,
select,
button,
table a,
.active-button.button {
	color: var(--tux-white);
}

legend {
	color: var(--tux-gray-60);
}

input::placeholder {
	color: var(--tux-gray-15);
}

.notes,
.notes button {
	color: var(--dark-yellow);
}

.guest-vip {
	color: var(--crown-yellow);
}

label,
button,
table a,
a.button,
input[type="checkbox"] {
	cursor: pointer;
}

button[disabled] {
	cursor: wait;
}

.flex,
header,
#logo,
.panel-bar,
.inline-label-field,
.inline-label-field-alt,
#list-single-buttons,
a.button {
	display: flex;
}

.full-viewport,
[popover]:popover-open {
	display: grid;
}

header a,
label,
button,
tr td a,
tr td:first-child span {
	display: block;
}

.msg-status a {
	display: inline-block;
}

.hide-inp + .inp,
[popover] {
	display: none;
}

#close-dialog {
	font-size: 3rem;
}

main h1 {
	font-size: 2.22rem;
}

header i {
	font-size: 1.98rem;
}

main h2 {
	font-size: 1.86rem;
}

main,
button,
dialog {
	font-size: 1.74rem;
}

table,
label em,
a.button,
td button,
.alert {
	font-size: 1.62rem;
}

header,
legend,
caption {
	font-size: 1.5rem;
}

em {
	font-style: italic;
}

strong,
table th {
	font-weight: bold;
}

#list-single-buttons {
	gap: 18px;
}

#check-in-progress {
	height: 12px;
}

header,
.panel-bar {
	justify-content: space-between;
}

.jc-cntr {
	justify-content: center;
}

#check-in-progress {
	left: 0;
}

#logo {
	letter-spacing: .1rem;
}


main h1,
table th,
table td {
	line-height: 2.1;
}

caption {
	line-height: 1.8;
}

input,
textarea,
select {
	line-height: 1.5;
}

form,
legend,
button,
table,
.panel,
#guest-search {
	margin: 0 auto;
}

i + .fa-pipe {
	margin: 0 3px;
}

.inline-label-field-alt input[type="checkbox"] {
	margin-left: 21px;
}

header + main {
	margin-top: var(--headerSize);
}

.msg-status p + a {
	margin-top: 24px;
}

main h1,
.msg-status p {
	margin-top: 12px;
}

button {
	margin-top: 6px;
}

td button {
	margin-top: 0;
}

#logo i {
	margin-right: .48ch;
}

.label-field,
.inline-label-field-alt {
	margin-bottom: 24px;
}

dialog p {
	margin-bottom: 18px;
}

main h1,
main h2,
.panel-bar {
	margin-bottom: 12px;
}

button,
label,
.label-field:last-child {
	margin-bottom: 6px;
}

main .panel h2,
.inline-label-field label,
td button {
	margin-bottom: 0;
}

a.button i + span {
	margin-left: .48ch;
}

.panel,
table,
#guest-search {
	max-width: 1020px;
}

form {
	max-width: 432px;
}

form {
	min-width: 288px;
}

.inline-label-field label {
	min-width: 108px;
}

button {
	min-width: 105px;
}

.in-pending {
	min-width: 87px;
}

table a {
	min-width: 36px;
}

td button {
	min-width: 3px;
}

body {
	min-height: calc(100svh - var(--headerSize));
}

.full-viewport {
	min-height: 100svh;
}

header {
	min-height: var(--headerSize);
}

td button:hover {
	opacity: 1;
}

progress {
	opacity: .96;
}

a.button:hover,
button:hover {
	opacity: .87;
}

input,
textarea,
select {
	outline: 0 none;
}

tr td:first-child span {
	overflow: hidden;
}

.table-wrapper {
	overflow-x: auto;
}

main h1 {
	padding-top: 24px;
}

main h1 {
	padding-bottom: 24px;
}

dialog,
[popover] {
	padding: 24px;
}

#dashboard-grid {
	padding: 15px;
}

header,
main,
footer,
.panel {
	padding: 12px;
}

input,
textarea,
select {
	padding: 6px;
}

header nav a {
	padding: 6px;
}

.alert {
	padding: 3px;
}

fieldset {
	padding: 24px 15px;
}

button {
	padding: 9px 27px;
}

table th,
table td {
	padding: 3px 6px;
}

a.button {
	padding: 6px 9px;
}

caption {
	padding: 3px 6px;
}

.container,
.msg-status {
	padding: 15px 0;
}

.container + .container {
	padding-top: 0;
}

legend {
	padding: 0 3px;
}

td button {
	padding: 0;
}

.full-viewport,
.note-popover {
	place-items: center;
}

#guest-search {
	position: sticky;
}

header,
#quick-links,
#check-in-progress,
[popover] {
	position: fixed;
}

.alert,
#close-dialog,
.close-note {
	position: absolute;
}

#form-container,
main {
	position: relative;
}

#quick-links,
.close-note {
	right: 12px;
}

.alert {
	right: 3px;
}

#close-dialog {
	right: 0;
}

main h1,
main h1 + h2,
dialog p,
caption,
table th,
.utility,
.msg-status,
.inline-label-field label {
	text-align: center;
}

#logo,
a.button,
table a {
	text-decoration: none;
}

.checked-in .name {
	text-decoration: line-through;
}

tr td:first-child span {
	text-overflow: ellipsis;
}

table th {
	text-transform: uppercase;
}

input[type="checkbox"] {
	transform: scale(1.8) translateY(-6%);
}

#close-dialog {
	transform: translate(-50%,-50%);
}

button {
	transition: all 180ms ease-in-out;
}

#guest-search {
	top: calc(var(--headerSize) + 12px);
}

#check-in-progress {
	top: var(--headerSize);
}

#quick-links {
	top: calc(var(--headerSize) + 12px);
}

.alert,
.close-note {
	top: 12px;
}

header,
#close-dialog {
	top: 0;
}

tr td:first-child span {
	white-space: nowrap;
}

header,
input,
textarea,
select,
table,
.panel,
#check-in-progress {
	width: 100%;
}
input[type="checkbox"] {
	width: auto;
}

header {
	z-index: 9;
}

#guest-search {
	z-index: 6;
}

#quick-links,
#check-in-progress {
	z-index: 1;
}



tr td:first-child span {
	width: 96%;
}

@media (max-width: 568px) {
	body {
		background-size: 78%;
	}

	#guest-search label span,
	#guest-search .button span {
		display: none;
	}

	#guest-search form {
		margin: 0;
	}

	#guest-search form,
	#guest-search label {
		min-width: 42px;
	}

	tr td:first-child span {
		max-width: 51vw;
	}
}



/* For WebKit browsers */
progress::-webkit-progress-value {
	background-color: var(--tux-red);
}

/* For Mozilla browsers */
progress::-moz-progress-bar {
	background-color: var(--tux-red);
}

/* For Internet Explorer */
progress::-ms-fill {
	background-color: var(--tux-red);
}

[popover] {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 87vw;
	height: 87svh;
	z-index: 999;
}
</style>
