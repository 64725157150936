import { createStore } from 'vuex'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function setValue(obj, keys, value) {
	console.log('set value fired')
	let current = obj;

	for (let i = 0; i < keys.length - 1; i++) {
		if (current[keys[i]] == null) {
			return;
		}

		current = current[keys[i]];
	}

	current[keys[keys.length - 1]] = value;
}

export default createStore({
	state: {
		backend: process.env.VUE_APP_API + process.env.VUE_APP_PATH,
		userConnect: {
			socketCurrent: null,
			socketPrevious: null,
			token: null,
			_id: null, // check
			email: null,
			role: null,
			teams: []
		},
		lists: []
	},
	getters: {
		listSingle: state => id => {
			return state.lists.find(list => list._id === id)
		},
		listsAll: state => {
			//.slice()
			return state.lists.slice().sort((a, b) => b.date.localeCompare(a.date));
		},
	},
	mutations: {
		setStorage(state,payload){
			if(payload){
				console.log('inside setStorage')
				state.userConnect.token = payload.token ? payload.token : null

				state.userConnect._id = payload._id ? payload._id : null // check
				state.userConnect.role = payload.role ? payload.role : null
				state.userConnect.email = payload.email ? payload.email : null
				let teams_arr = typeof payload.teams === 'string' ? JSON.parse(payload.teams) : payload.teams
				state.userConnect.teams = payload.teams ? teams_arr : []
			}
		},
		setLists(state,payload){
			state.lists = payload
		},
		setList(state,payload){
			let listRec = state.lists.find(obj => obj._id === payload._id);
			let listRecIndex = state.lists.findIndex(obj => obj._id === payload._id)
			if(listRec == null){
				console.log('rec not found, push')
				state.lists.push(payload)
			} else {
				console.log('list rec found')
				state.lists[listRecIndex] = payload
			}
		},
		updateStore(state,payload){
		
			console.log('%%% updating store %%%')
			console.log(state)
			console.log(payload)
			console.log('%%%')

			let collFieldsDel = {
				'users': ['password','session'],
				'lists': []
			}
		
			if(state[payload.ns.coll] !== undefined && payload.documentKey._id){
				let rec = null;
					rec = state[payload.ns.coll].find(r => r._id === payload.documentKey._id)
				let recIndex = state[payload.ns.coll].findIndex(r => r._id === payload.documentKey._id)
		
				switch(payload.operationType) {
					case 'insert':
						for (const [key] of Object.entries(payload.fullDocument)) {
							if(collFieldsDel[payload.ns.coll].includes(key)){
								delete payload.fullDocument[key]
							}
						}
		
						state[payload.ns.coll].push(payload.fullDocument)
						break
		
					case 'update':
						var key_arr,
							new_key_arr = []
		
						for (const [key, value] of Object.entries(payload.updateDescription.updatedFields)) {
							key_arr = key.split('.')
							key_arr.forEach(function(item){
								if(isNaN(Number(item))){
									new_key_arr.push(item);
								} else {
									new_key_arr.push(Number(item));
								}
							})
		
							if(rec != null){
								setValue(rec, new_key_arr, value);
							}
		
							key_arr,
							new_key_arr = []
						}
		
						payload.updateDescription.removedFields.forEach(function (key) {
							delete rec[key]
						})
						break
		
					case 'delete':
						state[payload.ns.coll].splice(recIndex,1);
						break
				}
			}
		},
		SOCKET_CONNECT() {},
		SOCKET_DISCONNECT() {},
		SOCKET_MESSAGECHANNEL() {},
	},
	actions: {
		setStorage(context,payload){
			context.commit('setStorage',payload)
		},
		SOCKET_updateStore(context,payload){
			context.commit('updateStore',payload)
		},
		async loadLists(context){

			let currentDate = dayjs()
			let adjustedDate = currentDate.subtract(6, 'hour').format('YYYY-MM-DD');

			try {
				const get_lists = await axios.get(`${context.state.backend}/lists?t=${context.state.userConnect.token}&d=${adjustedDate}`)
				context.commit('setLists',get_lists.data)
			} catch (e) {
				console.log(e)
			}
		},
		async loadList(context,payload){
			console.log('running load list....>')
			try {
				const get_list = await axios.get(`${context.state.backend}/list?t=${context.state.userConnect.token}&i=${payload}`)
				console.log('getting list in load list store',get_list)
				context.commit('setList',get_list.data)
			} catch (e) {
				console.log(e)
			}
		},
		async checkInGuest(context,payload){
			try {
				const checkin_guest = await axios.post(`${context.state.backend}/guest/checkin`,payload)
				return checkin_guest
			} catch (e) {
				console.log(e)
			}
		},
		async checkOutGuest(context,payload){
			try {
				const checkout_guest = await axios.post(`${context.state.backend}/guest/checkout`,payload)
				return checkout_guest
			} catch (e) {
				console.log(e)
			}
		},
		async removeGuest(context,payload){
			console.log('trying to remove guest... in action (store)')
			try {
				const remove_guest = await axios.post(`${context.state.backend}/guest/remove`,payload)
				return remove_guest
			} catch (e) {
				console.log(e)
			}
		}
	}
})
