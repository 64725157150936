import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/'
import axios from 'axios'

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/HomeView.vue')
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/account/RegisterView.vue')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/account/LoginView.vue')
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('../views/account/DashboardView.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/account',
		name: 'account',
		component: () => import('../views/account/AccountView.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/lists',
		name: 'Lists',
		component: () => import('../views/lists/ListsView.vue'),
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'Lists Index',
				component: () => import('../views/lists/ListsIndex.vue')
			},
			{
				path: 'add',
				name: 'Lists Add',
				component: () => import('../views/lists/ListsAdd.vue'),
			},
			{
				path: 'edit/:id',
				name: 'Lists Edit',
				component: () => import('../views/lists/ListsEdit.vue'),
			},
			{
				path: ':id',
				name: 'Lists Single',
				component: () => import('../views/lists/ListsSingle.vue'),
			},
		]
	},
	{
		path: '/guests',
		name: 'Guests',
		component: () => import('../views/guests/GuestsView.vue'),
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'Guests Index',
				component: () => import('../views/guests/GuestsIndex.vue')
			},
			{
				path: 'add',
				name: 'Guests Add',
				component: () => import('../views/guests/GuestsAdd.vue'),
			},
			{
				path: 'edit/:id',
				name: 'Guests Edit',
				component: () => import('../views/guests/GuestsEdit.vue'),
			},
		]
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import('../views/account/LogoutView.vue')
	},
	{
		path: '/:catchAll(.*)',
		name: '404 Not Found',
		component: () => import('../views/NotFound.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {

	console.log('before requiresAuth')

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

	function logoutClear(){
		console.log('logout clear...')
		store.state.userConnect = {}
		localStorage.clear()
	}
	console.log(to.path)

	async function authToken(){
		let resObj = {
			cont: false,
			where: null
		}

		let to_path = to.path;
			to_path = to_path.replace(`/${to.params.id}`,'')
console.log('about to try auth user')



		try {
			const authenticate_user = await axios.get(`${store.state.backend}/authenticate?t=${store.state.userConnect.token}&r=${to_path}`)

			if(authenticate_user.data.res === 'ok'){
				console.log('auth user')
				console.log(authenticate_user.data)
				console.log('---')
				//store.state.userConnect.id = authenticate_user.data._id

				if(authenticate_user.data.red === true){
					resObj.cont = false
					resObj.where = '/dashboard'
				} else {
					resObj.cont = true
				}

			} else {
				logoutClear()
				resObj.cont = false
				resObj.where = '/login'
			}

			return resObj
		} catch (e) {
			console.log(e)
		}
	}

	if(requiresAuth === true){
		console.log('ra')
		console.log(store.state.userConnect.token)
		console.log('--')

		if(store.state.userConnect.token){
			console.log('we have a token...')
			authToken().then(function(res){
				if(res.cont === true){
					next()
				} else {
					if(res.where === '/login'){
						logoutClear()
						window.location.href = '/login'
					}
					next(res.where)
				}
			})
		} else {
			logoutClear()
			//next('/login')
			window.location.href = '/login'
		}
	} else {
		next()
	}

	window.scrollTo(0, 0)
})

export default router
